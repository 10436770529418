<script>
  let visible = false;
</script>

<style>
  .container {
    position: relative;
    height: 12px;
    display: flex;
    justify-content: center;
  }
  .tooltip-container {
    width: max-content;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 24px;
  }
  .tooltip {
    background: #ffffff;
    padding: 4px 8px;
    border: 1px solid #e0e0e4;
    border-radius: 3px;
    line-height: 1.5;
  }
  .tooltip::before {
    content: "";
    background-image: url("/assets/tooltip-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    position: absolute;
    width: 100%;
    height: 9px;
    top: -8px;
    left: 0px;
  }
</style>

<div class="container">
  <img
    src="/assets/icon-hint.svg"
    alt="Hint icon"
    on:mouseenter={() => (visible = true)}
    on:mouseleave={() => (visible = false)} />
  {#if visible}
    <div class="tooltip-container">
      <div class="tooltip">
        <slot />
      </div>
    </div>
  {/if}
</div>
