<script>
  import FileInput from "@/FileInput.svelte";
  import Button from "@/Button.svelte";
</script>

<style>
  .about {
    width: 260px;
    background: #ffffff;
    border: 1px solid #e0e0e4;
    padding: 16px;
    align-self: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: inset 0px -1px 0px #e0e0e4;
    margin-bottom: 16px;
  }

  .header > h1 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .header > .version {
    background: #ededf0;
    border-radius: 3px;
    line-height: 1.5;
    padding: 0 6px;
    margin-bottom: 14px;
  }

  .description {
    margin-bottom: 16px;
  }

  .call-to-action > strong {
    margin-right: 8px;
  }

  .call-to-action > :global(button) {
    padding: 0 8px;
  }

  .names {
    position: absolute;
    bottom: 16px;
  }
</style>

<div class="about z-1">
  <header class="header">
    <h1>Polyphaser</h1>
    <div class="version">
      <strong>Alpha</strong>
      0.1.1
    </div>
  </header>
  <p class="description lh-copy">
    Explore possible color combinations of vector graphics.
  </p>
  <p class="call-to-action">
    <strong>To get started:</strong>
    <FileInput />
  </p>
  <div class="names grey">
    Created by Jonas Krøner & Sebastian Frelle Koch ヽ(^.^)人(^.^)ノ
  </div>
</div>
